<template>
	<div class="content">
		<div style="margin:auto">
			<div class="a_content">
			<div class="a_all1">
				<img class="logo" :src="myData.pcAppLogo? myData.pcAppLogo.img : ''" alt="">
				<div class="tellTitle">服务热线</div>
				<div class="phone1">{{myData.Telephone ? myData.Telephone.remarks : ''}}</div>
				<!-- <div class="phone1">{{myData.Telephone ? myData.Telephone.remarks : ''}}</div> -->
			</div>
			<div class="a_all2">
				<div @click="isCheck(item.id,index)" class="a_title2" v-for="(item,index) in allDataList" :key="index">{{item.title}}</div>
			</div>
			<!-- <div class="a_all2">	
				<div class="a_title2">法律声明</div>
				<div class="a_title2">加入我们</div>
				<div class="a_title2">常见问题</div>
				<div class="a_title3">专家机构版</div>
			</div> -->
			<!-- <div class="a_all3">
				<div class="a_title5">服务热线</div>
				<div class="a_title6">{{myData.Telephone ? myData.Telephone.remarks : ''}}</div>
			</div> -->
			<div class="a_all4" style="margin-right:28px;">
				<img class="code_img" :src="myData.gzh? myData.gzh.img : ''" alt="">
				<div class="a_title7">{{myData.gzh? myData.gzh.remarks : ''}}</div>
			</div>	
			<div class="a_all4">
				<img class="code_img" :src="myData.app? myData.app.img : ''" alt="">
				<div class="a_title7">{{myData.app? myData.app.remarks : ''}}</div>
			</div>
		</div>
		<div class="a_title8" @click="lianjie()">{{myData.filing ? myData.filing.remarks : ''}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				myData:[{img:''}],
				allDataList:[]
			}
			},
		activated(){
			this.dataAll()
		},
		methods:{
			//选中状态
			isCheck(e,a){
				if(a==0) this.aboutMe(e)
				if(a==1) this.liability(e)
				if(a==2) this.law(e)
				if(a==3) this.joinMe(e)
			},
			//获取所有数据
			dataAll(){
				this.$axios({
					url:'Pcimg/pcxxx',
					methods:'post'
				}).then(res=>{
					this.myData=res.data.data	
				})
				this.$axios({
					url:'article/getFooterList',
					methods:'post'
				}).then(res=>{
					this.allDataList=res.data.data	
				})
			},
			//跳转法律链接
			law(id){
				this.$router.push({path:'/new/privacy',query:{id:id}})
			},
			//跳转免责链接
			liability(id){
				this.$router.push({path:'/new/liability',query:{id:id}})
			},
			//跳转加入我们链接
			joinMe(id){
				this.$router.push({path:'/new/joinMe',query:{id:id}})
			},
			//跳转关于我们链接
			aboutMe(id){
				this.$router.push({path:'/new/aboutMe',query:{id:id}})
			},
			//跳转链接
			lianjie(){
				window.location.href='https://beian.miit.gov.cn/'
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		width:100%;
		padding: 20px 40px;
		box-sizing: border-box;
		background: #4C4C4C;
		display: flex;
		justify-content: center;
		.a_content {
			width: 1200px;
			display: flex;
			justify-content: center;
			.a_all1 {
				display: flex;
				height:230px;
                width:250px;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				.logo {
					width: 180px;
					height:64px;
					margin-right: 30px;
				}
				.tellTitle{
					font-size: 16px;
					font-weight: 400;
					color: #FFFFFF;
					margin-top: 30px;
				}
				.phone1{
					font-size:16px;
					font-weight: 500;
					color:#FFFFFF;
					margin: 10px 0px;
				}
				// .phone1:hover{
				// 	color:#F58235;
				// }
				// .tellTitle:hover{
				// 	color:#F58235;
				// }
				.a_title1 {
					font-size: 16px;
					font-weight: 600;
					color: #337CFF;
					margin-top:120px;
				}
			}

			.a_all2 {
				border-left: 1px solid #666666;
				width: 202px;
				margin-right: 100px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.a_title2:hover{
					color: #F58235;
					cursor: pointer;
				}
				.a_title2 {
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					margin-bottom: 30px;
				}
				.a_title3:hover{
					color: #F58235;
				}
				.a_title3 {
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
			.a_all3{
				border-left: 1px solid #666666;
				margin-right: 16px;
				.a_title5:hover{
					color: #F58235;
				}
				.a_title6:hover{
					color: #F58235;
				}
				.a_title5{
					font-size:14px;
					font-weight: 400;
					color: #FFFFFF;
					margin-top:90px;
					text-align: center;
				}
				.a_title6{
					font-size:16px;
					font-weight: 600;
					color: #337CFF;
					margin-top: 24px;
					// margin-left: 44px;
					text-align: center;
					width: 175px;
				}
			}
			.a_all4{
				display: flex;
				align-items:center;
				flex-direction: column;
				justify-content: center;	
				.code_img{
				width:140px;
				height:140px;
				background-color: #FFFFFF;
				margin: 0px 0px 24px 0px;	
				}
				.a_title7{
					font-size: 12px;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}
		.a_title8{
			font-size: 12px;
			font-weight: 400;
			color: #FFFFFF;
			margin-top:20px;
			text-align: center;
		}
		.a_title8:hover{
			color: #F58235;
			cursor: pointer;
		}
	}
</style>
