// 每次请求前都要携带token 如果不带，服务器会报401的错误
import axios from 'axios'
import router from '../router/index'
import store from '../store/index'

let http = axios.create({
    timeout:10000,
	//baseURL: 'https://a.s7a7a.com/api/',
	baseURL: 'https://college-wishes.syecms.com/api/',
	method: 'POST'
})
// 请求拦截---每次发起请求之前判断是否有token
// 如果有 将token保存在请求的header上
http.interceptors.request.use(request => {
    // 从本地获取token
    let token = store.state.userData.token
    // token为true（token有值）运行&&后面的语句
    token && (request.headers['token'] = `${token}`)
    return request
}, error => Promise.reject(error))
// 请求后
http.interceptors.response.use(response => {
    // 获取token保存在本地
    // response.data.token && sessionStorage.setItem('token', response.data.token)
    return response
}, error => {
	let _response = error.response
	switch (_response.status) {
	case 401:
		localStorage.removeItem('token')
			return router.push('/login')
	case 999:
		this.$store.commit('userInfo',"")
		this.$store.commit('getMember',"")
		this.$store.commit('vipData',"")
		return router.push({path:'/Login'})
	}
	return Promise.reject(error.response.data)
})
export default http
